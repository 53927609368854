<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new color</h1>
                <!-- <p class="text-caption text-left mt-8">This will create an independent form for storing your data.</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="green--text">Create new color</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <p>
                                Colors are used to identify your product, service, or organization.
                            </p>
                            <p>
                                The color label is publicly visible.
                                <!-- The comment is private and only shown to authorized users. -->
                            </p>

                            <!-- <v-select outlined dense :items="newColorTypeChoices" v-model="newColorType" color="green" label="Type" required hint="The kind of data to which this color will be applied">
                            </v-select> -->

                            <v-text-field
                                ref="colorLabelInput"
                                v-model="newColorLabel"
                                label="Name"
                                :rules="newColorLabelRules"
                                validate-on-blur
                                color="green"
                                required
                                type="text"
                                outlined
                                dense
                            >
                            </v-text-field>

                            <v-color-picker
                                v-model="newColorHex"
                                required
                                mode="rgba"
                                elevation="2"
                            >
                            </v-color-picker>

                            <!-- <v-textarea
                                ref="colorCommentInput"
                                v-model="newColorComment"
                                label="Comment"
                                hint="This is just for you to remember how you are using the color"
                                :rules="newColorCommentRules"
                                validate-on-blur
                                color="green"
                                type="text"
                                outlined
                                dense
                            >
                            </v-textarea> -->

                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
.v-input .v-input__prepend-outer {
    margin-left: 4px !important;
    padding-left: 4px !important;
}

.v-input .v-input__append-outer {
    margin-top: 0px !important;
    padding-top: 0px !important;
}
</style>

<script>
import { mapState } from 'vuex';
import { isValidHex } from '@/sdk/input';

export default {
    data: () => ({
        typeList: [],

        account: null,

        error: null,
        website: null,
        // create form
        createColorForm: null,
        // newFormAlias: null,
        // newFormAliasRules: [
        //     (v) => !!v || 'Display alias is required',
        //     (v) => !v || isValidFormAlias(compact(v)) || 'Form alias is required',
        // ],
        newColorLabel: null,
        newColorLabelRules: [
            (v) => !!v || 'Label is required',
        ],
        newColorHex: null,
        newColorHexRules: [
            (v) => !!v || 'Color is required',
            (v) => !v || isValidHex(v) || 'Color is invalid',
        ],
        newColorTimestamp: null,
        // newColorType: null,
        // newColorTypeChoices: [], // will be loaded from server
        newColorComment: null,
        newColorCommentRules: [
            // (v) => !!v || 'comment is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            return typeof this.newColorLabel === 'string' && this.newColorLabel.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        // async loadWebsite(id) {
        //     try {
        //         this.$store.commit('loading', { loadWebsite: true });
        //         const response = await this.$client.account(this.$route.params.accountId).currentAccount.website.get(id);
        //         console.log(`loadWebsite: response ${JSON.stringify(response)}`);
        //         if (response) {
        //             this.website = response;
        //             // if the form is for a website, suggest an appropriate form label (user can still edit this)
        //             if (this.newColorLabel === null || this.newColorLabel.length === 0) {
        //                 this.newColorLabel = `Website content for ${this.website.label}`;
        //             }
        //         }
        //     } catch (err) {
        //         console.error('failed to load website', err);
        //         this.website = null;
        //     } finally {
        //         this.$store.commit('loading', { loadWebsite: false });
        //     }
        // },
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createColor();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createColor() {
            if (Number.isInteger(this.newColorTimestamp) && this.newColorTimestamp + 500 > Date.now()) {
                return;
            }
            this.newColorTimestamp = Date.now();
            try {
                this.error = false;
                this.$store.commit('loading', { createColor: true });
                console.log('createColor');
                const hex = typeof this.newColorHex === 'string' ? this.newColorHex : this.newColorHex.hexa;
                const request = {
                    label: this.newColorLabel,
                    hex,
                    // comment: this.newColorComment,
                };
                const response = await this.$client.account(this.$route.params.accountId).color.create(request);
                console.log('createColor response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated && id) {
                    // const form = { id, ...request };
                    // this.list.push(form); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-color', params: { accountId: this.$route.params.accountId, colorId: id } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create color' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create color' });
                    this.error = 'Request failed';
                }
                // this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create color', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create color' });
            } finally {
                this.$store.commit('loading', { createColor: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        // this.loadTypeList();
    },
};
</script>
